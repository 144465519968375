require("whatwg-fetch");
require("react");
require("react-dom");
require("react-redux");
require("react-router");
require("seamless-immutable");
require("rsvp");
require("validate.js");
require("ipaddr.js");
var $ = require("jquery");

window.$ = window.jQuery = $;
